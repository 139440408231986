import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import customer from "../assets/img/mert-haciosmanoglu.png"
import team1 from "../assets/img/main/donusum.jpg"
import uiUx from "../assets/img/ui-ux-design.png"
import cro from "../assets/img/convertion-rate-optimization.png"
import webDev from "../assets/img/website-development.png"
import LeadGen from "../assets/img/lead-generation.png"
import checkModal from "../assets/img/check-modal.png";



class Convert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      value: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();

    this.setState({ value: e.target.value });
  }
  showModal(e) {

    this.setState({ display: !this.state.display });
    document.querySelector('.check-modal').classList.add('active-modal');

    document.querySelector('#site').innerHTML = this.state.value;
  }

  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector('.check-modal').classList.remove('active-modal');
  }
  handleSubmit(e) {

    e.preventDefault();
    const title = document.title.split('|',1).toString();  
    const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
      method: 'POST',
      body: JSON.stringify({
        "text_327649": title,
        "text_204202": document.querySelector('#site').value,
        "text_775908": document.querySelector('#name').value,
        "text_532192": document.querySelector('#email').value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    });
    response.then(res => {
      if (res.status === 200) {
        document.querySelector('.status').innerHTML = "Success!";
      }
      else {
        document.querySelector('.status').innerHTML = "Error";
      }
    });
  }
  render() {
    return (
      <Layout>
        <SEO title="Conversion" description="We turn the traffic that comes to your website into a paying customer." />
        <div className="convert">
          <div className="convert-hero container">
            <div className="col-md-4 noPadding">
              <div className="top">
                <h1>Conversion</h1>
                <p>We turn the traffic that comes to your website into a paying customer.</p>
                <p onClick={(e) => this.showModal()} className="content-link">Get free brand analysis.</p>
              </div>
              <div className="bottom">
                <h2>Liva Pattiserie</h2>
                <p>“Thank you for constantly trying to enlighten us on every step as well as enabling us to increase our sales through digital visibility.”</p>
                <div className="profile">
                  <div> <img alt="alt text" src={customer}></img></div>
                  <div><p>Mert Hacıosmanoğlu</p>
                    <p>Board Member</p></div>
                </div>
              </div>
            </div>
            <div className="col-md-8 convert-image">
              <img alt="alt text" src={team1} />
              <div className="right-content">
                <div className="col-md-4">
                  <h3>Return on Investment</h3>
                  <p>We have a very efficient return on your investment strategy in our digital campaigns.</p>
                </div>
                <div className="col-md-4">
                  <h3>Competitive Advantage</h3>
                  <p>We make sure that your brand is a few steps ahead of its competitors with increasing transformations.</p>
                </div>
                <div className="col-md-4">
                  <h3>Strong Brand Image</h3>
                  <p>We make sure that our campaigns positively contribute to creating a strong brand image.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="convert-traffic py60">
          <h2>Fine-tuning that will<br/> activate your traffic.</h2>
          <p><span>SERVICES <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg></span> CONVERSION</p>
        </div>
        <div className="services-list__block container">
          <div className="convert-service">
            <div className="col-md-4">
              <h2>UI/UX<br /> Design</h2>
              <p>We improve your website by focusing on user experience and make it fast and aesthetically pleasing within the framework of current digital dynamics.</p>
              <p className="small-content">
                - Web and mobile compatibility check<br />
                - Interface design up to date to catch current design trends<br />
                - Development of true elements suitable for user experience
        </p>
              <p> <Link to="/ui-ux-design/" className="content-link">Check out the UI / UX design process.</Link></p>
            </div>
            <div className="col-md-6">
              <img alt="alt text" src={uiUx}></img>
            </div>
          </div>

          <div className="convert-service" id="cro-optimizasyonu">
            <div className="col-md-6">
              <img alt="alt text" src={cro}></img>
            </div>
            <div className="col-md-4">
              <h2> Conversion Rate <br/>Optimization (CRO)</h2>
              <p>We promote your website traffic by taking targeted action (sales, form filling, booking, etc.) and work to increase the conversion rate which helps you to turn users into customers before leaving your website.</p>
              <p className="small-content">
                - Increasing quality traffic rates<br />
                - Improving your search engine ranking<br />
                - Website gelirlerini artırma
        </p>
              {/* <p> <Link to="/#/" className="content-link">CRO sürecine göz atın.</Link></p> */}
            </div>
          </div>
        </div>

        <div className="services-list__inline py60 container">
          <div className="convert-service">
            <div>
              <img alt="alt text" src={webDev}></img>
            </div>
            <div>
              <h2>Website<br />Enhancements</h2>
              <p>We make your website, your window to the digital world, much more functional for your users and enable your potential customers to spend more time there.</p>
              <p className="small-content">
                - Traffic increase<br />
                - Reducing the rate of immediate departure<br />
                - SEO compatibility
        </p>
              <p> <Link to="/web-development/" className="content-link">Check out the website development process.</Link></p>
            </div>

          </div>

          <div className="convert-service" id="lead-generation">
            <div>
              <img alt="alt text" src={LeadGen}></img>
            </div>
            <div>
              <h2>Lead <br />Generation</h2>
              <p>We find potential customers who may be interested in your products or services, now or in the future, and then we carry digital marketing efforts to suit them.</p>
              <p className="small-content">
                - Inbound marketing<br />
                - Demand generation
            </p>
              {/* <p> <Link className="content-link">Lead generation sürecine göz atın.</Link></p> */}
            </div>
          </div>
        </div>
        <div className="check-modal">
          <span onClick={(e) => this.closeModal()}>X</span>
          <h2 className="form-head">Fill the form</h2>             <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
          <div className="check-modal_container">
            <form onSubmit={this.handleSubmit} >
              <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus={true} name="text_204202" id="site" required  placeholder="Enter your website." />
              <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
              <input type="email" id="email" required  name="text_532192" placeholder="Enter yor email" />
              <button>SEND</button>
              <div className="status"></div>
            </form>
            <img alt="alt text" src={checkModal} />
          </div>
        </div>
      </Layout>
    )
  }
}
export default Convert
